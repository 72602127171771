<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Suppliers</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form @submit.prevent="getSuppliers">
      <v-row class="pt-8">
        <v-col class="col">
          <label>Name</label>
          <v-text-field v-model="name"></v-text-field>
        </v-col>
        <v-col class="col-3 pt-10">
          <v-btn type="sumbit">Search</v-btn>
          &nbsp;
          <v-btn @click="clearSearch">Clear</v-btn>
        </v-col>
      </v-row>
    </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-simple-table fixed-header height="400">
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Tel</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(supplier, index) in suppliers" :key="`s_${index}`">
              <td>{{ supplier.name }}</td>
              <td>{{ supplier.type_of_supply }}</td>
              <td>{{ supplier.telephone }}</td>
              <td>
                <router-link custom v-slot="{ navigate }" :to="`/purchasing/suppliers-view/${supplier.id}`">
                  <v-btn
                    x-small
                    @click="navigate"
                    @keypress.enter="navigate"
                    role="link">
                    View
                  </v-btn>
                </router-link>
                &nbsp;
                <router-link custom v-slot="{ navigate }" :to="`/purchasing/suppliers-edit/${supplier.id}`">
                  <v-btn
                    x-small
                    @click="navigate"
                    @keypress.enter="navigate"
                    role="link">
                    Edit
                  </v-btn>
                </router-link></td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-row class="no-print">
      <v-col>
        <v-btn @click="exportSuppliers">Export</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'PurchasingSuppliers',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      suppliers: [],
      page: 1,
      length: 0,
      totalVisible: 9,
      name: '',
    };
  },
  watch: {
    page() {
      this.getSuppliers();
    },
  },
  methods: {
    clearSearch() {
      this.name = '';
      this.getSuppliers();
    },
    getSuppliers() {
      const postData = {};
      postData.name = this.name;
      axios.post(`/suppliers/getAll/${this.page}.json?token=${this.token}`, postData)
        .then((response) => {
          this.suppliers = response.data.suppliers;
          this.length = response.data.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportSuppliers() {
      axios.get(`/spreadsheets/suppliers.json?token=${this.token}`, {responseType:'blob'})
          .then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'suppliers.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          });
    },
  },
  mounted() {
    this.getSuppliers();
  },
};
</script>
